/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchUsers, { fetchUser } from './action';

export const userSelector = (state) => state.user;

export const videosSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    user: null,
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchUser.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    },
    [fetchUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default videosSlice.reducer;
