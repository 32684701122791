import { Center, CircularProgress } from '@chakra-ui/react';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import AuthenticatedSixMonthPaidRoute from './AuthenticatedSixMonthPaidRoute';
import AuthenticatedMonthlyPaidRoute from './AuthenticatedMonthlyPaidRoute';
import AuthenticatedLightPackageRoute from './AuthenticatedLightPackageRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

const Login = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ 'pages/Auth/Login')
);
const Register = lazy(() =>
  import(/* webpackChunkName: "RegisterPage" */ 'pages/Auth/Register')
);
const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ 'pages/Auth/ForgotPassword'
  )
);
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "ResetPasswordPage" */ 'pages/Auth/ResetPassword')
);
const Settings = lazy(() =>
  import(/* webpackChunkName: "SettingsPage" */ 'pages/Settings')
);
const Screenings = lazy(() =>
  import(/* webpackChunkName: "ScreeningsPage" */ 'pages/Screenings')
);
const Introduction = lazy(() =>
  import(/* webpackChunkName: "IntroductionPage" */ 'pages/Introduction')
);
const Notebook = lazy(() =>
  import(/* webpackChunkName: "NotebookPage" */ 'pages/Notebook')
);
const Home = lazy(() =>
  import(/* webpackChunkName: "HomePage" */ 'pages/Home')
);
const WatchIt = lazy(() =>
  import(/* webpackChunkName: "WatchItPage" */ 'pages/WatchIt')
);
const Movies = lazy(() =>
  import(/* webpackChunkName: "MoviesPage" */ 'pages/WatchIt/Movies')
);
const MoviesShow = lazy(() =>
  import(/* webpackChunkName: "MoviesShowPage" */ 'pages/WatchIt/MoviesShow')
);
const StreamingShow = lazy(() =>
  import(
    /* webpackChunkName: "StreamingShowPage" */ 'pages/WatchIt/StreamingShow'
  )
);

const CurateIt = lazy(() =>
  import(/* webpackChunkName: "CurateItPage" */ 'pages/CurateIt')
);

const CurateItSurprise = lazy(() =>
  import(/* webpackChunkName: "CurateItPage" */ 'pages/Surprise')
);

const CurateItLessons = lazy(() =>
  import(/* webpackChunkName: "CurateItLessonsPage" */ 'pages/CurateIt/Lessons')
);
const AnalyseIt = lazy(() =>
  import(/* webpackChunkName: "AnalyseItPage" */ 'pages/AnalyseIt')
);
const AnalyseItLessons = lazy(() =>
  import(
    /* webpackChunkName: "AnalyseItLessonsPage" */ 'pages/AnalyseIt/Lessons'
  )
);
const FinanceIt = lazy(() =>
  import(/* webpackChunkName: "FinanceItPage" */ 'pages/FinanceIt')
);
const FinanceItLessons = lazy(() =>
  import(
    /* webpackChunkName: "FinanceItLessonsPage" */ 'pages/FinanceIt/Lessons'
  )
);
const EvaluateIt = lazy(() =>
  import(/* webpackChunkName: "EvaluateItPage" */ 'pages/EvaluateIt')
);
const EvaluateItLessons = lazy(() =>
  import(
    /* webpackChunkName: "EvaluateItLessonsPage" */ 'pages/EvaluateIt/Lessons'
  )
);
const ExecuteIt = lazy(() =>
  import(/* webpackChunkName: "ExecuteItPage" */ 'pages/ExecuteIt')
);
const ExecuteItLessons = lazy(() =>
  import(
    /* webpackChunkName: "ExecuteItLessonsPage" */ 'pages/ExecuteIt/Lessons'
  )
);
const MarketIt = lazy(() =>
  import(/* webpackChunkName: "MarketItPage" */ 'pages/MarketIt')
);
const MarketItLessons = lazy(() =>
  import(/* webpackChunkName: "MarketItLessonsPage" */ 'pages/MarketIt/Lessons')
);

const ScreenIt = lazy(() =>
  import(/* webpackChunkName: "ScreenItPage" */ 'pages/ScreenIt')
);

const DirectoryUsers = lazy(() =>
  import(/* webpackChunkName: "DirectoryUsersPage" */ 'pages/DirectoryUsers')
);

const DirectoryUser = lazy(() =>
  import(/* webpackChunkName: "DirectoryUserPage" */ 'pages/DirectoryUser')
);

const FilmsEvents = lazy(() =>
  import(/* webpackChunkName: "FilmsEventsPage" */ 'pages/FilmsEvents')
);

const FilmEvent = lazy(() =>
  import(/* webpackChunkName: "FilmEventPage" */ 'pages/FilmEvent')
);

const Chat = lazy(() =>
  import(/* webpackChunkName: "ChatPage" */ 'pages/Chat')
);

const Booking = lazy(() =>
  import(/* webpackChunkName: "BookingPage" */ 'pages/Booking')
);

const BookingDetails = lazy(() =>
  import(/* webpackChunkName: "BookingDetailsPage" */ 'pages/BookingDetails')
);

const Feedback = lazy(() =>
  import(/* webpackChunkName: "FeedbackPage" */ 'pages/Feedback')
);

const NotFound = lazy(() =>
  import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFound')
);

const PrivacyPolicy = lazy(() =>
  import(/* webpackChunkName: "NotFoundPage" */ 'pages/PrivacyPolicy')
);

export default function index() {
  return (
    <Suspense
      fallback={
        <Center w="full" h="100vh">
          <CircularProgress
            isIndeterminate
            color="whiskey.500"
            thickness="4px"
          />
        </Center>
      }
    >
      <Router>
        <Switch>
          <UnauthenticatedRoute exact path="/login/:watch?" component={Login} />
          <UnauthenticatedRoute
            exact
            path="/register/:id?/:watch?"
            component={Register}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <UnauthenticatedRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <UnauthenticatedRoute
            exact
            path="/reset-password"
            component={ResetPassword}
          />
          <AuthenticatedRoute exact path="/settings" component={Settings} />
          <AuthenticatedRoute exact path="/screenings" component={Screenings} />
          <AuthenticatedRoute
            exact
            path="/introduction"
            component={Introduction}
          />
          <AuthenticatedRoute exact path="/" component={Home} />
          <AuthenticatedRoute
            exact
            path="/booked-films-or-events"
            component={Booking}
          />
          <AuthenticatedRoute
            exact
            path="/booked-films-or-events/:id"
            component={BookingDetails}
          />
          <AuthenticatedRoute exact path="/feedback" component={Feedback} />

          <AuthenticatedRoute
            exact
            path="/surprise"
            component={CurateItSurprise}
          />

          <AuthenticatedSixMonthPaidRoute
            exact
            path="/watch-it/:id"
            component={WatchIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/watch-it/:id/movies"
            component={Movies}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/watch-it/:id/streaming/:id"
            component={StreamingShow}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/watch-it/:id/movies/:id"
            component={MoviesShow}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/analyse-it/:id"
            component={AnalyseIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/analyse-it/:id/lessons"
            component={AnalyseItLessons}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/finance-it/:id"
            component={FinanceIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/finance-it/:id/lessons"
            component={FinanceItLessons}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/execute-it/:id"
            component={ExecuteIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/execute-it/:id/lessons"
            component={ExecuteItLessons}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/evaluate-it/:id"
            component={EvaluateIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/evaluate-it/:id/lessons"
            component={EvaluateItLessons}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/market-it/:id"
            component={MarketIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/market-it/:id/lessons"
            component={MarketItLessons}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/curate-it/:id"
            component={CurateIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/curate-it/:id/lessons"
            component={CurateItLessons}
          />
          <AuthenticatedLightPackageRoute
            exact
            path="/screen-it/:id"
            component={ScreenIt}
          />
          <AuthenticatedSixMonthPaidRoute
            exact
            path="/notebook"
            component={Notebook}
          />
          <AuthenticatedMonthlyPaidRoute exact path="/chat" component={Chat} />
          <AuthenticatedRoute
            exact
            path="/directory"
            component={DirectoryUsers}
          />

          <AuthenticatedRoute
            exact
            path="/directory/:id"
            component={DirectoryUser}
          />
          <Route exact path="/watch" component={FilmsEvents} />
          <Route exact path="/watch/:id" component={FilmEvent} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
}
