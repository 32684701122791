import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchUsersRequest, { fetchUserRequest } from './apis';

const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchUsersRequest(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async ({ query, userId }, { rejectWithValue }) => {
    try {
      const res = await fetchUserRequest({ query, userId });
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default fetchUsers;
